import { addIndex, o, join, map } from "ramda";
import { shadesNums } from "../../constants/constants";
import { getTextColor, makeFileHref } from "../utils";

const mapIndexed = addIndex(map);
const joinStraight = join("");
const getTilesStringFromColumn = (columnIndex) =>
  o(
    joinStraight,
    mapIndexed((tile, index) => svgTile(tile, index, columnIndex))
  );

const getTilesColumns = o(
  joinStraight,
  mapIndexed((column, index) => getTilesStringFromColumn(index)(column))
);

export const createSvgString = (columns) => `<svg width="${columns.length *
  180}" height="1030" viewbox="0 0 2184 1030" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="2">
  <text x="10" y="20" font-family="'Poppins','Montserrat',sans-serif" font-size="12" fill="#161616">
    Generated with Toucan Colorizer made by Vojěch Bezpalec
  </text>
  ${getTilesColumns(columns)}
</svg>`;

const svgTile = (
  { name = "random", hex = "#aabbcc", HSLuv = 0 },
  rowIndex = 0,
  columnIndex = 0
) => {
  const textColor = getTextColor(HSLuv);
  const shade = shadesNums[rowIndex];

  return `<svg x="${columnIndex * 180}" y="${rowIndex * 100 +
    30}" width="180" height="100" viewBox="0 0 180 100">
  <path fill="${hex}" d="M0 0h180v100H0z"/>
  <text font-family="'Montserrat-Bold','Montserrat'" font-weight="700" font-size="12" fill="${textColor}" transform="translate(15.76 24.544)">
    ${hex}
  </text>
  <text font-family="'Montserrat-Regular','Montserrat'" font-size="12" fill="${textColor}" transform="translate(15.38 83.928)">
    ${name} ${shade}
  </text>
</svg>
`;
};

export const makeSVGFileHref = function(svgData) {
  const data = new Blob([svgData], {
    type: "image/svg+xml;charset=utf-8",
  });
  return makeFileHref(data);
};
