<template>
  <div class="container">
    <img :src="logo" class="logo" />
    <div class="content">
      <h1>Toucan Colorizer - create systematic color palettes</h1>
      <h2>Insert your colors in hex and then adjust</h2>
      <p>
        It maps your color to closest Luv value from pre-defined gray palette
        created and tweaked specially for human eye by IBM, which results in
        color palette with unified contrasts across same Luv values. You can
        insert max 8 colors. Just insert your starting hex colors and tweak them
        as you like. Default variables in export are pre-defined to the shade
        400, later there will be option to select it manually.
      </p>
      <ExportScheme :columns="columns" />
    </div>
  </div>
</template>

<script>
import ExportScheme from "./ExportScheme.vue";
import logo from "../assets/toucan-logo.svg";

export default {
  name: "Header",
  components: {
    ExportScheme,
  },
  props: ["columns"],
  data() {
    return { logo };
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 6rem;
  height: 6rem;
  margin: 5rem;
  @include for-phone-only {
    display: none;
  }
}
.container {
  display: flex;
}
.content {
  text-align: left;
  max-width: 50rem;
  padding: 1rem;
}
</style>