<template>
  <div class="container">
    <Header :columns="columns" />
    <ColorsPicker @addTiles="addTiles" />
    <Tiles :columns="columns" />
  </div>
</template>

<script>
import ColorsPicker from "./ColorsPicker.vue";
import Tiles from "./Tiles.vue";
import Header from "./Header.vue";
import { hsluvToHex } from "hsluv";
import { defaultGrays } from '../constants';

export default {
  name: "ColorBuilder",
  components: {
    Tiles,
    ColorsPicker,
    Header,
  },
  data() {
    return {
      tiles: [],
    };
  },
  computed: {
    columns() {
      const columns = this.tiles.map(({ hue, saturation, name }) => {
        const colored = defaultGrays.map((gray) => ({
          name: name,
          hex: hsluvToHex([hue, saturation, gray.HSLuv]),
          HSLuv: gray.HSLuv,
        }));
        return colored;
      });
      return [defaultGrays, ...columns];
    },
  },
  methods: {
    addTiles(tiles) {
      this.tiles = tiles;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 1rem 0 0 0;
  @include for-phone-only {
    width: 100%;
    overflow: hidden;
  }
}
h1 {
  margin: 0 0 1rem;
  padding: 0 1rem;
}
</style>