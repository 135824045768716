<template>
  <div class="color_tiles-column">
    <ColorTile
      v-for="(tile, index) in column"
      :key="index"
      :tile="tile"
      :index="index"
    />
  </div>
</template>

<script>
import ColorTile from "./ColorTile";

export default {
  name: "TilesColumn",
  components: {
    ColorTile,
  },
  props: {
    column: Array,
  },
};
</script>

<style lang="scss">
:root {
  --colWidth: 10vw;
  --rowHeight: 8rem;
  @include for-phone-only {
    --colWidth: 80vw;
  }
}
.color_tiles-column {
  display: flex;
  flex-direction: column;
  width: var(--colWidth);
}
</style>