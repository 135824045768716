<template>
  <ColorBuilder />
  <Footer />
</template>

<script>
import ColorBuilder from "./components/ColorBuilder.vue";
import Footer from "./components/Footer.vue";
import "vue-slider-component/theme/default.css";

export default {
  name: "App",
  components: {
    ColorBuilder,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #F4F4F4;
}
html,
body {
  background-color: #161616;
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
