<template>
  <div class="tile-container">
    <div class="color-tile" :style="cssVars" @click="copyHexToClipboard">
      <div v-if="!copied">
        <span v-if="index >= 0" :index="index">
          {{ tile.name + " " + shadesNums[index] }}
        </span>
        <div class="tile-hex">{{ tile.hex }}</div>
        <!-- <div class="tile-contrast" v-if="tile.HSLuv">{{ contrast }}w</div> -->
      </div>
      <span v-if="copied" class="copiedText">Copied!</span>
    </div>
  </div>
</template>

<script>
import { shadesNums } from "../constants";
import { getContrast, getTextColor } from "../utils";

export default {
  name: "ColorTile",
  props: {
    tile: Object,
    index: Number,
    justPreview: Boolean,
  },
  data() {
    return {
      shadesNums,
      copied: false,
    };
  },
  computed: {
    cssVars() {
      return {
        "--hoverScale": this.justPreview ? "scale(1)" : "scale(1.2)",
        "--cursor": this.justPreview ? "default" : "pointer",
        "--bg": this.tile.hex,
        "--color": getTextColor(this.tile.HSLuv),
      };
    },
    contrast() {
      return getContrast(this.tile.hex, this.tile.HSLuv);
    },
  },
  methods: {
    copyHexToClipboard() {
      if (this.justPreview) {
        return;
      }
      const el = document.createElement("textarea");
      el.value = this.tile.hex;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      el.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showCopiedMessage();
    },
    showCopiedMessage() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.color-tile {
  height: var(--rowHeight);
  width: 100%;
  background-color: var(--bg);
  color: var(--color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem;
  cursor: var(--cursor);
  transition: transform 300ms;

  @include for-phone-only {
    /* width: 60vw; */
  }

  &:hover {
    position: relative;
    z-index: 4;
    transform: var(--hoverScale);
  }
}
.tile-container {
  width: var(--colWidth);
}
.tile-contrast {
  font-size: 0.8rem;
  margin: 0.25rem 0 0 0;
}
.tile-hex {
  margin: 0.25rem 0 0 0;
  font-size: 1rem;
  font-weight: bold;
}
.copiedText {
  font-size: 1rem;
  font-weight: bold;
}
</style>