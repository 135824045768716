export const defaultGrays = [
  { name: "Gray", hex: "#161616", HSLuv: 7 },
  { name: "Gray", hex: "#262626", HSLuv: 15 },
  { name: "Gray", hex: "#393939", HSLuv: 24 },
  { name: "Gray", hex: "#525252", HSLuv: 35 },
  { name: "Gray", hex: "#6F6F6F", HSLuv: 47 },
  { name: "Gray", hex: "#8D8D8D", HSLuv: 59 },
  { name: "Gray", hex: "#A8A8A8", HSLuv: 69 },
  { name: "Gray", hex: "#C6C6C6", HSLuv: 80 },
  { name: "Gray", hex: "#E0E0E0", HSLuv: 89 },
  { name: "Gray", hex: "#F4F4F4", HSLuv: 96 }
];

export const shadesNums = [
  "900",
  "800",
  "700",
  "600",
  "500",
  "400",
  "300",
  "200",
  "100",
  "50"
];

export const HSLuvs = [0, 7, 15, 24, 35, 47, 59, 69, 80, 89, 96, 100];
