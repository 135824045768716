<template>
  <div class="color_tiles">
    <div class="labels">
      <div
        class="labels-label"
        v-for="(tile, index) in defaultGrays"
        :key="index"
      >
        Luv {{ tile.HSLuv }}
      </div>
    </div>
    <TilesColumn
      v-for="(column, index) in columns"
      :key="index"
      :column="column"
    />
  </div>
</template>

<script>
import TilesColumn from "./TilesColumn";
import { defaultGrays } from "../constants";

export default {
  name: "Tiles",
  components: {
    TilesColumn,
  },
  props: {
    columns: Array,
  },
  data() {
    return {
      defaultGrays,
    };
  },
};
</script>

<style scoped lang="scss">
.color_tiles {
  display: flex;
  overflow: hidden;
  @include for-phone-only {
    overflow: auto;
  }
}
.labels {
  display: flex;
  flex-direction: column;
  width: 8vw;
}
.labels-label {
  font-size: 0.75rem;
  height: var(--rowHeight);
  display: flex;
  justify-content: center;
  align-items: center;

  @include for-phone-only {
    padding: 1.25rem;
  }
}
</style>