<template>
  <div class="form">
    <div class="padded">
      <div class="header">
        <h3>Color {{ index + 1 }}</h3>
        <div
          v-if="index !== 0"
          class="removeFormBtn"
          @click="$emit('remove-form')"
        >
          -
        </div>
      </div>
      <input class="form-item" v-model="hex" @input="saveColor" />
      <input class="form-item" v-model="name" @input="saveColor" />
      <VueSlider
        class="form-item"
        v-model="hue"
        @change="saveColor"
        :tooltip-formatter="hueFormatter"
        :min="0"
        :interval="0.5"
        :max="360"
      />
      <VueSlider
        class="form-item"
        v-model="saturation"
        @change="saveColor"
        :tooltip-formatter="saturationFormatter"
        :min="0"
        :interval="0.5"
        :max="100"
      />
    </div>
    <ColorTile :tile="originalTile" justPreview />
    <ColorTile :tile="closestTile" justPreview />
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import ColorTile from "./ColorTile.vue";
import { hexToHsluv, hsluvToHex } from "hsluv";
import { HSLuvs } from "../constants";

export default {
  name: "ColorPickerForm",
  components: {
    VueSlider,
    ColorTile,
  },
  props: {
    index: Number,
    tile: Object,
  },
  data() {
    return {
      hue: this.tile.hue,
      saturation: this.tile.saturation,
      hex: this.tile.hex,
      name: this.tile.name,
      hueFormatter: "H {value}",
      saturationFormatter: "S {value}",
    };
  },
  methods: {
    saveColor() {
      console.log("this.index", this.index);
      this.$emit("add-color", this.index, {
        hue: this.hue,
        saturation: this.saturation,
        color: this.hex,
        name: this.name,
      });
    },
  },
  watch: {
    hex(value) {
      const [hue, saturation] = hexToHsluv(value);
      this.hue = Math.round(hue);
      this.saturation = Math.round(saturation);
      this.saveColor();
    },
  },
  computed: {
    originalTile() {
      return {
        hex: this.hex,
        name: this.name,
      };
    },
    closestTile() {
      const Luv = hexToHsluv(this.hex)[2];
      const needle = Math.round(Luv);
      const closest = HSLuvs.reduce((a, b) => {
        return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
      });
      return {
        hex: hsluvToHex([this.hue, this.saturation, closest]),
        name: this.name,
      };
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.padded {
  padding: 1rem;
}
.removeFormBtn {
  cursor: pointer;
  border-radius: 50%;
  background-color: red;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.removeFormBtn:hover {
  transform: scale(1.2);
}
.form {
  width: var(--colWidth);
  text-align: left;
}
.form-item {
  width: 100%;
  margin: 0.5rem 0;
}
input {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid #161616;
  color: #161616;
  background-color: #f4f4f4;
}
</style>