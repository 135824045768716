<template>
  <button @click="downloadSvg">Download scheme as .svg</button>
  <button @click="downloadJs">Download scheme as .js</button>
  <button @click="downloadSass">Download scheme as .scss</button>
  <a id="download">Download</a>
</template>

<script>
import { createSvgString, makeSVGFileHref } from "../utils/export/svg";
import {
  createSassVariablesString,
  makeSassFileHref,
} from "../utils/export/sass";
import {
  createTailwindColorsString,
  makeJsFileHref,
} from "../utils/export/tailwind";

export default {
  name: "ExportScheme",
  props: {
    columns: Array,
  },
  methods: {
    downloadFile(file, fileName) {
      const downloadBtn = document.querySelector("#download");
      downloadBtn.setAttribute("href", file);
      downloadBtn.setAttribute("download", fileName);
      downloadBtn.click();
    },
    downloadSvg() {
      const svgData = createSvgString(this.columns);
      const file = makeSVGFileHref(svgData);
      this.downloadFile(file, "toucan-scheme.svg");
    },
    downloadJs() {
      const jsString = createTailwindColorsString(this.columns);
      const file = makeJsFileHref(jsString);
      this.downloadFile(file, "toucan-scheme.js");
    },
    downloadSass() {
      const scssString = createSassVariablesString(this.columns);
      const file = makeSassFileHref(scssString);
      this.downloadFile(file, "toucan-scheme.scss");
    },
  },
};
</script>

<style scoped lang="scss">
#download {
  display: none;
}
button {
  border-radius: 5px;
  border: none;
  background-color: #ea1c38;
  color: #f4f4f4;
  padding: 0.5rem 1rem;
  margin: 1rem 1rem 1rem 0;
  transition-duration: 300ms;
  transition-property: background-color, color;
  cursor: pointer;

  &:hover {
    background-color: hsl(352, 83%, 45%);
  }
}

.saveBtn {
  background-color: #27e50f;
  color: #161616;

  &:hover {
    background-color: #0a6002;
    color: #f4f4f4;
  }
}
</style>
