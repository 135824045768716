import { hex } from "wcag-contrast";

export const getContrast = (hexColor, hsluv) =>
  Math.round(hex(hexColor, getTextColor(hsluv)) * 10) / 10;

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const letters = "0123456789ABCDEF";
export function getRandomColor() {
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getTextColor = (HSLuv) => {
  return HSLuv > 47 ? "#161616" : "#F4F4F4";
};

export const makeFileHref = (data) => {
  // If we are replacing a previously generated file we need to
  // manually revoke the object URL to avoid memory leaks.
  if (window.textFile !== null) {
    window.URL.revokeObjectURL(window.textFile);
  }
  window.textFile = window.URL.createObjectURL(data);
  return window.textFile;
};

export function replaceAt(array, index, value) {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}