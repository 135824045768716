import { makeFileHref } from "../utils";

const createTailwindColorString = (colorColumn) => {
  return `
${colorColumn[0].name.toLowerCase().replace(/ /g, "_")}: {
  default: "${colorColumn[5].hex}", // 400
  50: "${colorColumn[9].hex}",
  100: "${colorColumn[8].hex}",
  200: "${colorColumn[7].hex}",
  300: "${colorColumn[6].hex}",
  400: "${colorColumn[5].hex}",
  500: "${colorColumn[4].hex}",
  600: "${colorColumn[3].hex}",
  700: "${colorColumn[2].hex}",
  800: "${colorColumn[1].hex}",
  900: "${colorColumn[0].hex}",
},
  `;
};

export const createTailwindColorsString = (columns) => {
  let colorsString = "const colors = {";
  columns.forEach((colorColumn) => {
    colorsString += createTailwindColorString(colorColumn);
  });
  colorsString += "}";
  return colorsString;
};

export const makeJsFileHref = (jsData) => {
  const data = new Blob([jsData], {
    type: "text/javascript",
  });
  return makeFileHref(data);
};
