<template>
  <div class="wrapper">
    <div class="labels">
      <div class="label">Inserted colors:</div>
      <div class="label">Closest adjusted colors:</div>
    </div>
    <div class="pickers">
      <ColorPickerForm
        v-for="(tile, index) in tiles"
        :key="tile.id"
        :index="index"
        :tile="tile"
        @addColor="addColor"
        @removeForm="removeForm(index)"
      />
      <div
        v-if="tiles.length < 8"
        @click="addColorPickerForm"
        class="addColorPickerBtn"
      >
        Add color +
      </div>
    </div>
  </div>
</template>

<script>
import { getRandomColor } from "../utils";
import { over, lensIndex, mergeDeepLeft } from "ramda";
import ColorPickerForm from "./ColorPickerForm";
import { hexToHsluv } from "hsluv";

export default {
  name: "ColorsPicker",
  components: {
    ColorPickerForm,
  },
  data() {
    return {
      tiles: [], // max 8
    };
  },
  emits: ["addTiles", "add-tiles"],
  methods: {
    addColor: function (index, tile) {
      const newTiles = over(lensIndex(index), mergeDeepLeft(tile), this.tiles);
      this.tiles = newTiles;
      this.$emit("add-tiles", newTiles);
    },
    addColorPickerForm() {
      if (this.tiles.length < 8) {
        const hex = getRandomColor();
        const [_hue, _saturation] = hexToHsluv(hex);
        this.tiles.push({
          id: Math.floor(Math.random() * 100),
          hue: Math.round(_hue),
          saturation: Math.round(_saturation),
          hex,
          name: "Color name",
        });
        this.$emit("add-tiles", this.tiles);
      }
    },
    removeForm(index) {
      console.log("index", index);
      this.tiles.splice(index, 1);
      this.$emit("add-tiles", this.tiles);
    },
  },
  mounted() {
    this.addColorPickerForm();
    this.addColorPickerForm();
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  padding: 0 0 0 8vw;
  margin-bottom: 2rem;
  @include for-phone-only {
    padding: 0 1rem;
  }
}
.pickers {
  display: flex;
  position: relative;

  @include for-phone-only {
    padding: 0;
    overflow: auto;
  }
}
h2 {
  font-size: 1.35rem;
  margin: 0 0 1rem;
}
p {
  margin: 0 auto 1rem;
  max-width: 500px;
}
.labels {
  width: var(--colWidth);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  padding: 0 1rem 0 0;

  .label {
    height: var(--rowHeight);
    font-size: 0.8rem;
    line-height: 1rem;
    padding-top: 2.5rem;
  }
}
.addColorPickerBtn {
  width: var(--colWidth);
  text-align: center;
  padding: 1rem;
  background-color: #262626;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: #393939;
  }
}
</style>