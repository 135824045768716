import { makeFileHref } from "../utils";

export const makeSassFileHref = function(text) {
  // const json = JSON.stringify(text);
  const data = new Blob([text], {
    type: "text/plain",
    endings: "native",
  });
  return makeFileHref(data);
};

const createSassColorVariablesString = (colorColumn) => {
  const name = colorColumn[0].name.toLowerCase().replace(/ /g, "_");
  return `
$${name}: (
  default: '${colorColumn[5].hex}',
  50: '${colorColumn[9].hex}',
  100: '${colorColumn[8].hex}',
  200: '${colorColumn[7].hex}',
  300: '${colorColumn[6].hex}',
  400: '${colorColumn[5].hex}',
  500: '${colorColumn[4].hex}',
  600: '${colorColumn[3].hex}',
  700: '${colorColumn[2].hex}',
  800: '${colorColumn[1].hex}',
  900: '${colorColumn[0].hex}'
);
    `;
};

export const createSassVariablesString = (columns) => {
  let str = "";
  columns.forEach((colorColumn) => {
    str += createSassColorVariablesString(colorColumn);
  });
  console.log("str", str);
  return str;
};
