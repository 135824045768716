<template>
  <div>
    Designed and created by
    <a href="http://weby.bezpalec.com">Vojtěch Bezpalec</a>
    <br><br>
    Inspired by
    <a
      target="_blank"
      href="https://blog.jirichlebus.cz/2019/11/18/jak-tvorit-systematicke-barvy-pro-digitalni-produkty/"
      >article</a
    >
    written by Jiří Chlebus.
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
div {
  padding: 1rem;
}
a {
  font-weight: 700;
  color: red;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>